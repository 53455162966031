var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "wrapper" } }, [
    _c("img", {
      attrs: { id: "logo", src: require("@/assets/web-figures/logo.png") },
    }),
    _c("p", { style: _vm.styles.header, attrs: { id: "header" } }, [
      _vm._v("お問い合わせありがとうございます。"),
    ]),
    _c("p", { style: _vm.styles.body, attrs: { id: "body" } }, [
      _vm._v(" 内容を順次確認し、折り返させていただきます。"),
      _c("br"),
      _c("br"),
      _vm._v(" 3 営業日以内に返信が確認できなかった場合は、"),
      _c("br"),
      _vm._v(
        " お手数ですが info@iflab.co.jp ( フッターに記載あり ) までご連絡をお願いいたします。 "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }